@font-face {
    font-family: Montserrat;
    src: url(Montserrat/Montserrat-Thin.ttf);
}

@font-face {
    font-family: Montserrat-Medium;
    src: url(Montserrat/Montserrat-Medium.ttf);
}

@font-face {
    font-family: Montserrat-Regular;
    src: url(Montserrat/Montserrat-Regular.ttf);
}
